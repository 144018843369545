@font-face {
    font-family: 'barlowbold';
    src: url('../fonts/barlow-bold/barlow-bold-webfont.eot');
    src: url('../fonts/barlow-bold/barlow-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/barlow-bold/barlow-bold-webfont.woff2') format('woff2'),
         url('../fonts/barlow-bold/barlow-bold-webfont.woff') format('woff'),
         url('../fonts/barlow-bold/barlow-bold-webfont.ttf') format('truetype'),
         url('../fonts/barlow-bold/barlow-bold-webfont.svg#barlowbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'barlowextrabold';
    src: url('../fonts/barlow-extrabold/barlow-extrabold-webfont.eot');
    src: url('../fonts/barlow-extrabold/barlow-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/barlow-extrabold/barlow-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/barlow-extrabold/barlow-extrabold-webfont.woff') format('woff'),
         url('../fonts/barlow-extrabold/barlow-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/barlow-extrabold/barlow-extrabold-webfont.svg#barlowextrabold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'barlowregular';
    src: url('../fonts/barlow-regular/barlow-regular-webfont.eot');
    src: url('../fonts/barlow-regular/barlow-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/barlow-regular/barlow-regular-webfont.woff2') format('woff2'),
         url('../fonts/barlow-regular/barlow-regular-webfont.woff') format('woff'),
         url('../fonts/barlow-regular/barlow-regular-webfont.ttf') format('truetype'),
         url('../fonts/barlow-regular/barlow-regular-webfont.svg#barlowregular') format('svg');
    font-weight: normal;
    font-style: normal;
}