// Margins
@include margin-variant(30);
@include margin-variant(40);
@include margin-variant(60);
@include margin-variant(70);

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


#mapa-brasil,
.mapa-brasil{
	color: #000;

	use{
		fill: #c7c8ca;

		&:hover{
			fill: darken(#c7c8ca, 10%);
		}
	}

	.active use{
		fill: $azul;
	}
}
