.topo{

	&.fx{
		@include media-breakpoint-only(xl) {
			.segura-menu{
				width: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 900;
				animation: fadeInDown 0.4s;
			}
		}
		@include media-breakpoint-down(lg) {
			.mbl-controls{
				width: 100%;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 900;
				animation: fadeInDown 0.4s;
			}
		}
	}
	//&.fx

	ul{
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	.redes{
		a{
			transition: all 0.2s linear;

			&:hover{
				color: $azul;
			}
		}
		a + a{
			margin-left: 6px;
			padding-left: 6px;
			border-left: 1px solid #dbdbdb;
		}
	}

	.barra-topo{
		font-size: 14px;
		line-height: 1;
		border-bottom: 1px solid #dbdbdb;
		padding: 7px 0;
		color: $preto;
		position: relative;
		z-index: 2;
		background-color: #fff;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.bem-vindo{
			span{
				color: $azul;
				font-family: $barlow-extrabold;
			}
		}
		.catalogo{
			a{
				color: $azul;
				font-family: $barlow-extrabold;

				i{
					color: $preto;
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.bem-vindo{
				flex-grow: 1;
			}
			.catalogo{
				margin-right: 30px;
			}
		}
		//UP LG
		@include media-breakpoint-down(lg) {
			.catalogo span,
			.redes{
				display: none;
			}
		}
	}
	//.barra-topo

	.main-navigation{
		.segura-infos{
			.contatos{
				.box-midia{
					.icone{
						display: flex;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						background-color: $cinza;
						color: #454545;
						line-height: 1;
					}
					.body{
						color: $preto;
						font-size: 15px;
						line-height: 1.2;

						.title{
							font-family: $barlow-bold;
							padding-bottom: 3px;
							line-height: 1;
							margin-bottom: 5px;
							position: relative;

							&:before{
								content: '';
								position: absolute;
								bottom: 0;
								width: 25px;
								height: 2px;
								display: block;
								background-color: #000;
							}
						}
					}
				}
				//box-midia
			}
			//contatos
		}
		//.segura-infos

		.campoBusca{
			background-color: $preto;
			.group{
				position: relative;

				.form-control{
					height: 42px;
					background-color: #fff;
					border-radius: 21px;
					color: #606060;
					border: none;
					background-color: $cinza;

					&::placeholder{
						color: #606060;
					}

					&:focus{
						box-shadow: none;
					}
				}

				button{
					border: none;
					background: $cinza;
					color: $preto;
					outline: none;
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.meu-container{
				width: 100%;
				max-width: 1140px;
				margin-left: auto;
				margin-right: auto;
				padding-left: 15px;
				padding-right: 15px;

			}
			.segura-infos{
				padding: 10px 0;

				.meu-container{
					display: flex;
					align-items: center;
				}
				.contatos{
					display: flex;
					align-items: center;
					flex-grow: 1;
					justify-content: space-between;
					margin-left: 130px;

					.box-midia{
						display: flex;
						align-items: center;
						.icone{
							width: 60px;
							height: 60px;
							font-size: 24px;
						}
						.icone{
							margin-right: 15px;
						}
						.body{
							.title{
								&:before{
									left: 0;
								}
							}
						}
					}
				}
				//contatos
				.redes{
					display: none;
				}
			}
			//.segura-infos
			
			.segura-menu{
				background-color: $azul;

				.meu-container{
					display: flex;
				}

				.menu{
					font-family: $barlow-bold;
					font-size: 15px;
					color: #fff;
					display: flex;
					flex-grow: 1;
					justify-content: space-between;

					.menu-li{
						display: flex;

						&.active{
							.menu-a{
								color: $preto;
								&:before{
									height: 5px;
								}
							}
						}
						.menu-a{
							display: flex;
							align-items: center;
							padding: 0 10px;
							position: relative;
							transition: all 0.1s ease-out;

							&:before{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 0;
								background-color: $preto;
								transition: all 0.1s ease-out;
							}

							&:hover{
								color: $preto;
								&:before{
									height: 5px;
								}
							}
						}
						&.dropdown{
							&:hover{
								.menu-a{
									color: $preto;
									&:before{
										height: 5px;
									}
								}
								.collapse{
									display: block;
								}
							}
							.item-drop{
								display: flex;
								.plus{
									display: none;
								}
							}
							.dropMenu{
								position: absolute;
								top: 100%;
								background-color: $preto;
								left: 50%;
								transform: translateX(-50%);
								min-width: 200px;
								max-width: 200px;
								text-align: center;
								z-index: 50;

								li + li{
									border-top: 1px solid $azul;
								}

								a{
									display: block;
									padding: 8px;
									font-size: 14px;
									line-height: 1;

									&:hover{
										background-color: lighten($preto, 8%);
									}
								}
							}
						}
						//dropdown
					}
				}
				//.menu

				.campoBusca{
					position: relative;
					padding: 10px 0 10px 20px;
					margin-left: 50px;

					&:after{
						content: '';
						position: absolute;
						width: 100vh;
						height: 100%;
						left: 100%;
						top: 0;
						background-color: inherit;
					}

					.form-control{
						width: 300px;
					}
				}
			}
			//.segura-menu
		}
		//DESKTOP

		@include media-breakpoint-down(lg) {
			position: fixed;
			top: 0;
			left: -250px;
			background-color: $cinza;
			z-index: 1000;
			width: 250px;
			height: 100%;
			border-right: 1px solid #000;
			overflow: auto;
			display: flex;
			flex-direction: column;
			transition: all 0.4s ease-out;
			padding-bottom: 20px;
			opacity: 0;

			&.active{
				left: 0;
				opacity: 1;
			}

			.segura-menu{
				order: 1;
				margin-bottom: 15px;
				position: relative;
				background-color: $azul;

				&:before{
					content: 'MENU';
					position: relative;
					width: 100%;
					display: block;
					font-size: 20px;
					color: #fff;
					background-color: #132531;
					font-family: $barlow-extrabold;
					line-height: 1;
					padding: 10px;
					text-align: center;
				}

				.menu{
					color: #fff;
					font-family: $barlow-bold;
					font-size: 16px;
					border-top: 1px solid $preto;
					border-bottom: 1px solid $preto;

					.menu-li + .menu-li{
						border-top: 1px solid $preto;
					}

					.active .menu-a{
						background-color: $preto;
					}

					.menu-a{
						display: flex;
						padding: 5px;
					}

					.dropdown{
						.item-drop{
							position: relative;

							.plus{
								width: 34px;
								display: block;
								height: 100%;
								position: absolute;
								right: 0;
								top: 0;
								cursor: pointer;

								&:before,
								&:after{
									content: '';
									position: absolute;
									background-color: #fff;
									transition: all 0.3s linear;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
								&:before{
									width: 15px;
									height: 3px;
								}
								&:after{
									width: 3px;
									height: 15px;
								}
							}

							.plus[aria-expanded="true"] {
								&:after{
									height: 0;
								}
							}
						}
						//item-drop

						.dropMenu{
							ul{
								border-top: 1px solid $preto;
								background-color: #4d7c9a;
							}

							li + li{
								border-top: 1px solid $preto;
							}

							a{
								display: block;
								padding: 5px 10px;
								line-height: 1;
							}
						}
					}
				}

				.campoBusca{
					padding: 10px 5px;
					background-color: $azul;
					border-bottom: 1px solid $preto;
				}
			}

			.segura-infos{
				order: 2;
				text-align: center;

				.logo{
					display: none;
				}
				.box-midia  + .box-midia{
					margin-top: 15px;
				}
				.box-midia{
					.icone{
						width: 30px;
						height: 30px;
						font-size: 16px;
						margin: 0 auto 5px auto;
					}
					.title{
						&:before{
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
				.redes{
					font-size: 20px;
					margin-top: 20px;
				}
			}
			//.segura-infos
		}
	}
	//.main-navigation

	.mbl-controls{
		padding: 5px 0;
		background-color: #fff;
		border-bottom: 1px solid $azul;
		box-shadow: 0 0 8px rgba(0,0,0,.4);

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			.logo{
				width: 50px;
				height: auto;
			}
			.botao{
				width: 30px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: $azul;
				color: #fff;
				border-radius: 10px;
				border: 1px solid #dbdbdb;
				cursor: pointer;
			}
		}

		@include media-breakpoint-only(xl) {
			display: none;
		}
	}
	//.mbl-controls
}