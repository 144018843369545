.rodape{
	border-top: 1px solid #dbdbdb;

	.content{
		padding: 30px 0;
		background-color: #fff;

		.logo{
			text-align: center;
			margin-bottom: 30px;
		}

		.box-midia{
			text-align: center;

			.icone{
				color: #454545;
				font-size: 25px;
				line-height: 1;
			}
			.body{
				color: $preto;
				font-size: 15px;
				line-height: 1.2;

				.title{
					font-family: $barlow-bold;
					padding-bottom: 3px;
					line-height: 1;
					margin-bottom: 10px;
					margin-top: 10px;
					position: relative;

					&:before{
						content: '';
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 25px;
						height: 2px;
						display: block;
						background-color: #000;
					}
				}
			}
		}
		//box-midia

		@include media-breakpoint-between(sm, md) {
			.col-sm-6:first-child{
				margin-bottom: 15px;
			}
			.col-sm-6:nth-child(2){
				margin-bottom: 15px;
			}
		}
		@include media-breakpoint-only(xs) {
			.col-sm-6 + .col-sm-6{
				margin-top: 15px;
			}
		}
	}
	//.content

	.autorais{
		background-color: $preto;
		padding: 15px 0;	

		.frases{
			color: $azul;
			font-size: 10px;
			
			line-height: 1;

			a{
				display: inline-flex;
				align-items: center;

				img{
					margin-left: 5px;
				}
			}
		}

		.redes{
			a{
				color: #afafaf;
				transition: all 0.2s linear;
				&:hover{
					color: $azul;
				}
			}
			a + a{
				margin-left: 6px;
				padding-left: 6px;
				border-left: 1px solid #545454;
			}
		}

		@include media-breakpoint-only(xl) {
			.frases{
				display: flex;
				align-items: center;

				a{
					margin-left: 10px;
				}
			}
			.redes{
				text-align: right;
			}
		}
		@include media-breakpoint-down(lg) {
			text-align: center;

			.frases{
				margin-top: 5px;
				a{
					justify-content: center;
				}
			}
		}
	}
}