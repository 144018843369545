.banners{
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;

	.carousel-indicators{
		left: 0;
		right: auto;
		margin-bottom: 0;

		li{
			width: 22px;
			height: 4px;
			border-radius: 1px;
			border: none;
			position: relative;
			z-index: 1;

			&:before{
				content: '';
				position: absolute;
				top: -3px;
				left: 0;
				width: 100%;
				height: 10px;
				background-color: transparent;
				z-index: -1;
			}
		}

		@include media-breakpoint-only(xl) {
			margin-left: calc(calc(100% - 1110px)/2);
			margin-right: calc(calc(100% - 1110px)/2);
			bottom: 20px;
		}
		@include media-breakpoint-only(lg) {
			margin-left: calc(calc(100% - 930px)/2);
			margin-right: calc(calc(100% - 930px)/2);
			bottom: 15px;
		}
		@include media-breakpoint-only(md) {
			margin-left: calc(calc(100% - 690px)/2);
			margin-right: calc(calc(100% - 690px)/2);
			bottom: 10px;
		}
		@include media-breakpoint-down(sm) {
			left: 50%;
			transform: translateX(-50%);
			margin: 0;
			bottom: 5px;
		}
	}
}
//.banners

.diferenciais{
	.title{
		text-transform: uppercase;
		font-size: 46px;
		line-height: 1;
		font-family: $barlow-extrabold;
		color: rgba(26,26,26,0.15);
		margin-top: 20px;
	}
	.segura-boxes{
		.box{
			.nome{
				color: $preto;
				font-size: 14px;
				line-height: 1;
				font-family: $barlow-extrabold;
				text-transform: uppercase;
			}
		}

		@include media-breakpoint-up(sm) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			max-width: 540px;

			.box{
				width: 100%;
				max-width: 164px;
				display: flex;
				align-items: center;

				.img{
					margin-right: 10px;
					border-right: 1px solid #d1d1d1;
					padding-right: 10px;
				}
			}
		}
	}
	@include media-breakpoint-up(sm) {
		.title{
			margin-bottom: -20px;
		}
	}
	@include media-breakpoint-down(lg) {
		.title{
			text-align: center;
		}
		.segura-boxes{
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media-breakpoint-only(xs) {
		text-align: center;
		.segura-boxes{
			.box + .box{
				margin-top: 15px;
			}
			.box{
				.img{
					.lazyimage{
						margin: 0 auto 5px auto;
					}
				}
			}
		}
	}
}
//.diferenciais

.quem-somos{
	padding-top: 30px;
	background-color: $cinza;
	overflow: hidden;

	.btn-padrao{
		margin-top: 30px;
	}

	.diferenciais{
		@include media-breakpoint-up(sm) {
			.box:first-child{
				animation-delay: 0s;
			}
			.box:nth-child(2){
				animation-delay: 0.3s;
			}
			.box:last-child{
				animation-delay: 0.6s;
			}
		}
	}

	@include media-breakpoint-only(xl) {
		.col-xl-6.align-self-xl-center{
			padding-top: 15px;
			padding-bottom: 15px;
		}
	}

	@include media-breakpoint-down(lg) {
		.title-1{
			text-align: center;
		}
		.title-2{
			text-align: center;

			&:before{
				left: 50%;
				transform: translateX(-50%);
			}
		}
		figure.col-xl-6{
			margin-top: 40px;

			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}
		}
		.btn-padrao{
			margin-left: auto;
			margin-right: auto;
		}
	}
}
//.quem-somos

.select-custom{
	margin-top: 30px;
	.form-control{
		width: 220px;
		height: 50px;
		border-radius: 25px;
		background-color: $preto;
		color: #fff;
		text-transform: uppercase;
		font-family: $barlow-bold;
		-webkit-appearance: none;  /* Remove estilo padrão do Chrome */
	   	-moz-appearance: none; /* Remove estilo padrão do FireFox */
	   	appearance: none; /* Remove estilo padrão do FireFox*/
	   	background-image: url('../images/detalhe-select.png');
	   	background-position: 200px center;
	   	background-repeat: no-repeat;
	   	cursor: pointer;
	   	padding-left: 20px;
	   	outline: none;
	   	border: none;

	   	&:focus{
	   		box-shadow: none;
	   	}

	   	@include media-breakpoint-down(sm) {
	   		margin-left: auto;
	   		margin-right: auto;
	   	}
	}
}
//.select-custom

.card-produtos{
	display: block;
	box-shadow: 0 0 0px rgba(0,0,0,.4);
	border-radius: 10px;
	overflow: hidden;
	padding: 15px 10px;
	text-align: center;
	font-size: 14px;
	line-height: 1.2;
	transition: all 0.5s linear;
	width: 100%;
	max-width: 252px;
	margin-left: auto;
	margin-right: auto;

	.img{
		width: 190px;
		height: 190px;
		margin-left: auto;
		margin-right: auto;
		background-color: $cinza;
		border-radius: 50%;
		overflow: hidden;
		margin-bottom: 20px;
		transition: all 0.5s linear;
	}
	.nome{
		font-family: $barlow-extrabold;
		text-transform: uppercase;
		color: $preto;
		margin-bottom: 5px;
		line-height: 1;
	}
	.texto{
		color: #494949;
		margin-bottom: 5px;
	}
	i.fas{
		color: $azul;
		font-size: 16px;
		opacity: 0;
		transition: all 0.4s linear;
	}

	&:hover{
		box-shadow: 0 0 7px rgba(0,0,0,.4);

		.img{
			background-color: #6aaad3;
		}
		i.fas{
			opacity: 1;
		}
	}
}
//.card-produtos

.produtos{
	padding: 50px 0;

	.title-1,
	.title-2{
		text-align: center;
	}
	.title-2{
		&:before{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	#owlProdutos{
		.item{
			padding: 7px;
		}
		.owl-nav{
			.owl-prev,
			.owl-next{
				width: 30px;
				height: 26px;
				background-color: $cinza;
				display: flex;
				align-items: center;
				justify-content: center;
				line-height: 1;
				font-size: 14px;
				color: $preto;
				transition: all 0.3s linear;

				&:hover{
					background-color: $azul;
					color: #fff;
				}
			}
			.owl-prev{
				border-radius: 13px 0 0 13px;
			}
			.owl-next{
				border-radius: 0 13px 13px 0;
			}
		}

		@include media-breakpoint-up(md) {
			padding-top: 40px;

			.owl-nav{
				position: absolute;
				top: -10px;
				right: 0;
				width: 64px;

				.owl-prev,
				.owl-next{
					position: absolute;
					bottom: 0;
				}
				.owl-prev{
					left: 0;
				}
				.owl-next{
					right: 0;
				}
			}
		}
		//UP MD
		@include media-breakpoint-down(sm) {
			padding-top: 60px;

			.owl-nav{
				position: absolute;
				top: 20px;
				left: 50%;
				transform: translateX(-50%);
				width: 64px;

				.owl-prev,
				.owl-next{
					position: absolute;
					top: 0;
				}
				.owl-prev{
					left: 0;
				}
				.owl-next{
					right: 0;
				}
			}
		}
	}
	//owlProdutos

	.btn-padrao{
		margin: 30px auto 0 auto;
	}
}
//produtos

.card-representantes{
	width: 100%;
	max-width: 150px;
	margin-left: auto;
	margin-right: auto;
}

.representantes{
	background-color: #e1eaf0;
	padding: 30px 0;

	.title-2{
		text-align: center;

		&:before{
			left: 50%;
			transform: translateX(-50%);
		}
	}

	#owlRepresentantes{
		.owl-nav{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;

			.owl-prev,
			.owl-next{
				position: absolute;
				top: 0;
				transform: translateY(-50%);
				font-size: 20px;
				color: #000;
				transition: all 0.2s linear;

				&:hover{
					color: $azul;
				}
			}
			.owl-prev{
				left: 0;
			}
			.owl-next{
				right: 0;
			}
		}
	}
}
//representantes