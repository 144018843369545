.internas{
	@include media-breakpoint-only(xl) {
		padding: 80px 0;
	}
	@include media-breakpoint-only(lg) {
		padding: 70px 0;
	}
	@include media-breakpoint-only(md) {
		padding: 60px 0;
	}
	@include media-breakpoint-only(sm) {
		padding: 50px 0;
	}
	@include media-breakpoint-only(xs) {
		padding: 40px 0;
	}

	&.pag-quem-somos{
		.title-1{
			text-align: center;
		}
		.title-2{
			text-align: center;

			&:before{
				left: 50%;
				transform: translateX(-50%);
			}
		}
		figure.col-xl-6{
			margin-top: 40px;

			.lazyimage{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	//&.pag-quem-somos

	.form-estilos{
		form{
			margin-top: 15px;
		}
		.form-group{
			margin-bottom: 5px;

			input{
				height: 50px;
			}

			.form-control{
				background-color: #e4e4e4;
				color: #949494;
				font-size: 14px;
				border: 1px solid transparent;
				border-radius: 4px;

				&::placeholder{
					color: #949494;
				}
				&:focus{
					box-shadow: none;
					border-color: $azul;
				}
			}
		}
		.botao{
			width: 150px;
			height: 54px;
			display: flex;
			align-self: center;
			border-radius: 27px;
			background-color: $azul;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $barlow-bold;
			font-size: 14px;
			text-align: center;
			transition: all 0.2s linear;
			border: none;
			outline: none;
			margin-top: 15px;

			&:hover{
				color: #fff;
				background-color: darken($azul, 10%);
			}
		}

		@include media-breakpoint-down(lg) {
			margin-bottom: 40px;
			.title-1{
				text-align: center;
			}
			.title-2{
				text-align: center;
				&:before{
					left: 50%;
					transform: translateX(-50%);
				}
			}
			
			.botao{
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
	//.form-estilos

	#mapaContato{
		padding-bottom: percentage(412/560);
	}

	&.pag-produtos{
		.title-1{
			text-align: center;
		}
		.title-2{
			text-align: center;
			&:before{
				left: 50%;
				transform: translateX(-50%);
			}
		}
		.card-produtos{
			margin-top: 30px;
		}

		#carouselFotos{
			width: 100%;
			max-width: 230px;
			margin-left: auto;
			margin-right: auto;

			.lazyimage{
				border-radius: 50%;
				margin-left: auto;
				margin-right: auto;
				overflow: hidden;
				border: 1px solid #dbdbdb;
			}
			.controles{
				position: absolute;
				top: 50%;
				left: 50%;
				z-index: 2;
				transform: translate(-50%, -50%);
				width: 100%;

				.prev,
				.next{
					position: absolute;
					font-size: 18px;
					color: #000;
					top: 0;
					transform: translateY(-50%);

					&:hover{
						color: $azul;
					}
				}
				.prev{
					left: 0;
				}
				.next{
					right: 0;
				}
			}
		}
		//#carouselFotos

		.title-2.nome{
			font-size: 18px;
			margin-bottom: 10px;
			
			@include media-breakpoint-up(lg) {
				text-align: left;

				&:before{
					left: 0;
					transform: translateX(0);
				}
			}
			@include media-breakpoint-down(md) {
				margin-top: 15px;
			}
		}

		#tabTopicos{
			margin-top: 40px;
			border: none;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 20px;

			.nav-link{
				font-size: 17px;
				line-height: 1;
				padding: 18px 10px;
				border-radius: 3px;
				background-color: #eee;
				font-family: $barlow-bold;
				text-transform: uppercase;
				color: $preto;
				text-align: center;
				margin: 5px;
				transition: all 0.3s linear;

				&.active,
				&:hover{
					background-color: $azul;
					color: #fff;
				}
			}
		}
		//tabTopicos
	}
	//&.pag-produtos

	&.pag-representantes{
		.title-2{
			margin-top: 0;
			font-size: 18px;
			line-height: 1;
			color: $azul;
			font-family: $barlow-regular;
		}
		.select-custom{
			margin-top: 0;
			margin-bottom: 20px;


			.form-control{
				background-color: #ededed;
				font-size: 13px;
				color: #343434;
				background-image: url('../images/detalhe-select2.png');
				margin-left: 0;
			}
		}
		.h2-representantes{
			font-size: 18px;
			line-height: 1;
			color: $azul;
			font-family: $barlow-bold;
			margin-bottom: 15px;
		}
		.item-representantes{
			font-size: 14px;
			color: #494949;
			line-height: 1.2;
			margin-bottom: 20px;

			.title{
				font-family: $barlow-bold;
			}
		}
	}
	//&.pag-representantes
}

.paginacao{
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 40px;

	li + li{
		margin-left: 3px;
	}

	.active a{
		background-color: $azul;
	}
	a{
		width: 22px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: #dbdbdb;
		color: $preto;
		line-height: 1;
		font-size: 13px;
		font-family: $barlow-bold;
		transition: all 0.2s linear;

		&:hover{
			background-color: $azul;
		}
	}
}