body{
	font-family: $barlow-regular;
}
body,
html{
	width:100%;
	min-height:100vh;
}
img[style]{
    max-width: 100%;
    height: auto !important;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow:1;
	}
}
textarea{
	resize: none;
}
figure{
	margin: 0;
}
h1,h2,h3,h4,h5,h6,p{
	margin-bottom: 0;
}
a{
    color: inherit;
}
a:focus,
a:hover{
    color: inherit;
    text-decoration: none;
}
*[data-animate]{
	opacity: 0;
}
.animated[data-animate]{
	opacity: 1;
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(#000, 0.6);
}

#go-topo{
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 995;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    transform: translateY(80px);
    background-color: $azul;
    color: #fff;
    box-shadow: 0 0 6px rgba(#000, 0.6);
    transition: all .4s ease-out;
    font-size: 20px;

    &:hover{
    	background-color: $cinza;
    	color: $preto;
    }
}
#go-topo.active{
    transform: translateY(0);
}

.title-1{
	font-size: 18px;
	line-height: 1;
	color: $azul;
	text-transform: uppercase;
}
.title-2{
	font-size: 28px;
	font-family: $barlow-extrabold;
	color: $preto;
	line-height: 1;
	margin-top: 10px;
	padding-bottom: 10px;
	position: relative;
	margin-bottom: 20px;
	text-transform: uppercase;

	&:before{
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 60px;
		height: 4px;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-image: url('../images/detalhe-title.png');
	}
}
.desc{
	font-size: 14px;
	line-height: 1.2;
	color: #494949;

	iframe{
		max-width: 100%;
	}
	img{
		max-width: 100%;
		height: auto;
	}

	a:hover{
		text-decoration: underline;
	}

	&.editor{
		p + p{
			margin-top: 14px;
		}
	}
}
.btn-padrao{
	width: 220px;
	height: 54px;
	display: flex;
	align-self: center;
	border-radius: 27px;
	background-color: $azul;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	font-family: $barlow-bold;
	font-size: 14px;
	text-align: center;
	transition: all 0.2s linear;

	i.fas{
		opacity: 0;
		transition: all 0.2s linear;
	}

	&:hover{
		color: #fff;
		background-color: #132531;
		
		i.fas{
			opacity: 1;
		}
	}
}